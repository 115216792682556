import React, { useMemo } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, func, node, number, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { ModalInMobile, PrimaryButton, H2 } from '../../components';
import BookingTimeForm from './BookingTimeForm/BookingTimeForm';

import css from './OrderPanel.module.css';
import { EVENT_TYPE } from '../../constants/listing';
import { useSelector } from 'react-redux';
import { parseDateFromISO8601, resetToStartOfDay } from '../../util/dates';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;

    history.push(`${pathname}${searchString}`, { ...state });
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search } = location;

  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;

  history.goBack(`${pathname}${searchString}`);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location,
  setlistinginitvalues
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.

  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => {
        openOrderModal(isOwnListing, isClosed, history, location);
        setlistinginitvalues();
      };
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const { price, publicData, validListingTypes, intl, marketplaceCurrency } = props;
  const { listingType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return (
    <div className={css.priceContainerInCTA}>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="General.from" />
      </div>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
    </div>
  );
};

const PriceMissing = () => {
  return (
    <p className={css.error}>
      <FormattedMessage id="OrderPanel.listingPriceMissing" />
    </p>
  );
};

const InvalidCurrency = () => {
  return (
    <p className={css.error}>
      <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
    </p>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    txLineItems,
    depositLineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    initialValues: orderInitialValues,
    bookingFormRef,
    onBookingFormChange,
    setInitialValues,
  } = props;

  const { currentUser } = useSelector(state => state.user);

  const publicData = listing?.attributes?.publicData || {};
  const {
    unitType,
    transactionProcessAlias = '',
    availabilityStartTime,
    availabilityEndTime,
    addOns,
    serviceableLocations,
  } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const price = listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  const showPriceMissing = isPaymentProcess && !price;

  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const showBookingTimeForm = !isClosed && timeZone;

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const showClosedListingHelpText = listing.id && isClosed;
  const vatRegistered = listing?.attributes?.publicData?.vatNumber;

  const {
    address,
    origin,
    dates,
    pub_startTime,
    pub_serviceableLocations,
    orderOpen,
    venueName,
    postalCode,
    city,
    streetName,
  } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const isOrderOpen = !!orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  // Use origin as key for dependecy instead of origin object
  const originAsKey = location.search.origin;

  const initialValuesFromSearch = useMemo(
    () => ({
      bookingStartTime: pub_startTime,
      ...(dates
        ? {
            bookingStartDate: { date: parseDateFromISO8601(dates) },
            bookingEndDate: { date: resetToStartOfDay(parseDateFromISO8601(dates), null, 1) },
            bookingEndTime:
              availabilityEndTime === '00:00' || availabilityEndTime < availabilityStartTime
                ? '23:30'
                : availabilityEndTime,
          }
        : {}),
      location:
        address && origin
          ? {
              search: address,
              predictions: [],
              selectedPlace: {
                address,
                origin,
                bounds: null,
                postalCode: postalCode,
                name: venueName,
                line1: streetName,
                city: city,
              },
            }
          : null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      address,
      availabilityEndTime,
      availabilityStartTime,
      dates,
      originAsKey,
      pub_serviceableLocations,
      pub_startTime,
    ]
  );

  const initialValues = useMemo(
    () => ({
      eventType: EVENT_TYPE.WEDDING,
      ...initialValuesFromSearch,
      ...orderInitialValues,
      venueName: initialValuesFromSearch?.location || {
        selectedPlace: orderInitialValues?.location?.selectedPlace,
        search: orderInitialValues?.location?.selectedPlace?.name,
      },
      streetName:
        initialValuesFromSearch?.location?.selectedPlace?.line1 ||
        orderInitialValues?.location?.selectedPlace?.line1,
      city:
        initialValuesFromSearch?.location?.selectedPlace?.city ||
        orderInitialValues?.location?.selectedPlace?.city,
    }),
    [initialValuesFromSearch, orderInitialValues]
  );

  const setListingPageInitialValues = () =>
    setInitialValues &&
    setInitialValues(
      {
        orderData: {
          order: bookingFormRef?.current?.getState()?.values,
          listingId: listing?.id?.uuid,
        },
      },
      'ListingPage'
    );

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => {
          closeOrderModal(history, location);
          setListingPageInitialValues();
        }}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.orderHeading}>
          {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        </div>

        {showPriceMissing ? (
          <PriceMissing />
        ) : showInvalidCurrency ? (
          <InvalidCurrency />
        ) : showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="OrderPanelBookingTimeForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            addOns={addOns}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            txLineItems={txLineItems}
            depositLineItems={depositLineItems}
            listingGeolocation={listing?.attributes?.geolocation}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
            initialValues={initialValues}
            availabilityStartTime={availabilityStartTime}
            availabilityEndTime={availabilityEndTime}
            formRef={bookingFormRef}
            serviceableLocations={serviceableLocations}
            currentUser={currentUser}
            vatRegistered={vatRegistered}
            onFormChange={onBookingFormChange}
          />
        ) : !isKnownProcess ? (
          <p className={css.errorSidebar}>
            <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
          </p>
        ) : null}
      </ModalInMobile>
      <div className={css.openOrderForm}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
        />

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <PrimaryButton
            onClick={handleSubmit(
              isOwnListing,
              isClosed,
              showInquiryForm,
              onSubmit,
              history,
              location,
              setListingPageInitialValues
            )}
          >
            <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,
  bookingFormRef: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
