import { capitalizeWord } from '../util/string';

export const ADD_ONS_CATEGORY = {
  CEREMONY: 'ceremony',
  DRINKS_RECEPTION: 'drinksReception',
  EVENING: 'evening',
  LATE_NIGHT: 'lateNight',
};

export const ADD_ONS_OPTIONS = Object.values(ADD_ONS_CATEGORY).map(value => ({
  value,
  label: `General.category${capitalizeWord(value)}`,
}));

export const MEMBERS_TYPE = {
  EXTRA: 'extra',
  EXISTING: 'existing',
};

export const MEMBERS_TYPE_OPTIONS = Object.values(MEMBERS_TYPE).map(value => ({
  value,
  label: `General.members${capitalizeWord(value)}`,
}));

export const EVENT_TYPE = {
  WEDDING: 'wedding',
  CORPORATE_EVENT: 'corporateEvent',
  BIRTHDAY_PARTY: 'birthdayParty',
  OTHER: 'other',
};

export const EVENT_TYPE_OPTIONS = Object.values(EVENT_TYPE).map(value => ({
  value,
  label: `General.${value}`,
}));

export const ADD_ON_SET_UP_OPTIONS = [
  { value: '0min', label: '0 Min' },
  { value: '5min', label: '5 Min' },
  { value: '10min', label: '10 Min' },
  { value: '15min', label: '15 Min' },
  { value: '20min', label: '20 Min' },
  { value: '30min', label: '30 Min' },
  { value: '45min', label: '45 Min' },
  { value: '60min', label: '1H' },
  { value: '75min', label: '1H and 15Min' },
  { value: '90min', label: '1H and 30Min' },
  { value: '105min', label: '1H and 45Min' },
  { value: '120min', label: '2H' },
  { value: '135min', label: '2H 15Min' },
  { value: '150min', label: '2H 30Min' },
  { value: '165min', label: '2H 45Min' },
  { value: '180min', label: '3H' },
];

export const GENERAL = 'general';
export const DETAILS = 'details';
export const PRICING = 'pricing';
export const PRICING_AND_STOCK = 'pricing-and-stock';
export const DELIVERY = 'delivery';
export const LOCATION = 'location';
export const AVAILABILITY = 'availability';
export const GALLERY = 'gallery';
export const ADD_ONS = 'add-ons';
export const FAQ = 'faq';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  GENERAL,
  DETAILS,
  PRICING,
  PRICING_AND_STOCK,
  DELIVERY,
  LOCATION,
  AVAILABILITY,
  GALLERY,
  ADD_ONS,
  FAQ,
];
